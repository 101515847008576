import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../../../utils/index'
import tw from 'tailwind.macro'
import classnames from 'classnames'

const Container = styled.div`
  ${tw`
    bg-cover
    xl:w-full
  `}

  margin-left: -24px;
  margin-right: -24px;

  ${screen('xl')} {
    margin-left: 0;
    margin-right: 0;
`

const Inner = styled.div`
  ${tw`
    px-4
    xl:px-0
  `}
`

const Icon = styled.img`
  max-height: 60px;
  max-width: 100%;
`
const IconContainer = styled.span`
  ${tw`
    py-2
    mr-3
    leading-normal
    justify-center 
    
  `};
  width: 50px;
  max-width: 50px;
  min-width: 50px;

  ${screen('lg')} {
    min-width: unset;
  }
`

function ListWithIcons({
  title,
  description,
  twoColumns,
  useBackground,
  items,
}) {
  return (
    <Container>
      <div className={`lg:py-3 py-3 ${useBackground ? 'bg-light-grey-1' : ''}`}>
        <div className="my-0 mx-auto max-w-2xl">
          <Inner>
            <h3
              className={classnames(
                'font-sans',
                'font-bold',
                'text-2xl',
                'leadind-none',
                'pb-3'
              )}
            >
              {title}
            </h3>
            {description ? (
              <div
                className={classnames(
                  'font-lg',
                  'lg:pb-2',
                  'pb-3',
                  'font-sans',
                  'leading-normal'
                )}
              >
                {description}
              </div>
            ) : null}
            {items && items.length > 0 ? (
              <div
                className={`flex flex-col md:flex-row flex-wrap  ${
                  twoColumns ? 'lg:flex-row lg:flex-wrap' : 'lg:flex-col'
                } `}
              >
                {items.map((item, index) => (
                  <div
                    className={`w-full md:w-1/2 ${
                      twoColumns ? 'lg:w-1/2' : 'lg:w-full'
                    } lg:mb-0mb-2 font-sans font-lg leading-normal flex items-center `}
                    key={`item_${index}`}
                  >
                    <IconContainer className="flex">
                      <Icon src={item.icon} />
                    </IconContainer>
                    <span>{item.text}</span>
                  </div>
                ))}
              </div>
            ) : null}
          </Inner>
        </div>
      </div>
    </Container>
  )
}

export default ListWithIcons
